import React from 'react';
import styled from 'styled-components';

import LightPage from './../../components/LightPage';
import { TextSection, Declaration, SectionHeading } from '../../components/textComponents';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
];

const page = {
  title: 'Thank You For Inquiring',
  keywords: keywords,
};

const heading = {
  title: page.title,
};

export default () => (
  <LightPage page={page} heading={heading}>
    <TextSection css={`
      margin-bottom: 60rem;
    `}>
      <SectionHeading>Thank You!</SectionHeading>
      <Declaration>
        Thank you for requesting a free quote!  We will look over your information and contact you to further discuss your needs and provide pricing information.
      </Declaration>
    </TextSection>
  </LightPage>
);